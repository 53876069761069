<template>
  <div>
    <!-- modal trigger button -->


    <!-- basic modal -->
    <b-modal id="modal-1" title="Rincian RUC Perawatan RutinRuas Jalan " ok-disabled no-close-on-backdrop size="lg"
      no-close-on-esc hide-footer>
      <b-row>
        <b-col md="12">
          <small class="text-primary">Ruas Jalan </small>
          <h6><strong class="text-primary"> {{ detail_ruas_jalan.nama_ruas_jalan }}</strong><br>
            <small>
              <strong> {{ detail_ruas_jalan.nm_wil }} </strong> </small>
          </h6>
          <b-row>
            <b-col md="4">
              <h5><small>Total </small> <br><strong class="text-primary">{{ detail_ruas_jalan.panjang_ruas }} KM</strong>
              </h5>
            </b-col>
            <b-col md="4">
              <h5><small>Panjang Aspal </small> <br><strong class="text-primary">{{ detail_ruas_jalan.panjang_aspal }}
                  KM</strong></h5>
            </b-col>
            <b-col md="4">
              <h5><small>Panjang Beton </small> <br><strong class="text-primary">{{ detail_ruas_jalan.panjang_beton }}
                  KM</strong></h5>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="12"> <small class="text-primary">Biaya Pemeliharan Rutin </small>
              <h3><strong class="text-primary"><sup>Rp. </sup> {{ Rp(detail_ruas_jalan.perawatan_rutin) }}</strong> </h3>
            </b-col>
            <!-- //tambahan penjumlahan panjang kilo meter
            {{ detail_ruas_jalan }} -->
          </b-row>
          <hr>
          <b-row>
            <b-col md=12>
              <h6 class="text-primary"><b-avatar rounded="" variant="primary" size="25"><feather-icon icon="GridIcon"
                    size='15' /></b-avatar> Rincian Ruas Jalan </h6>
              <b-alert variant="warning" show>
                <div class="alert-body">
                  <b-row>
                    <b-col>
                      <b-row>
                        <b-col col="12"><strong class="text-primary">Kondisi Ruas Jalan 2023</strong></b-col>
                      </b-row>
                      <b-row>
                        <b-col col="12"></b-col>
                      </b-row>
                      <b-row>
                        <b-col md="3" xs="3" sm="3" lg="3">
                          <small>Aspal Baik</small>
                          <h5><strong class="text-dark">{{ detail_ruas_jalan.aspal_baik }} </strong><small></small></h5>
                        </b-col>
                        <b-col md="3" xs="3" sm="3" lg="3">
                          <small>Aspal Sedang</small>
                          <h5><strong class="text-dark">{{ detail_ruas_jalan.aspal_sedang }} </strong><small></small></h5>
                        </b-col>
                        <b-col md="3" xs="3" sm="3" lg="3">
                          <small>Aspal Rusak Ringan</small>
                          <h5><strong class="text-dark">{{ detail_ruas_jalan.aspal_rusak_ringan }}
                            </strong><small></small>
                          </h5>
                        </b-col>
                        <b-col md="3" xs="3" sm="3" lg="3">
                          <small>Aspal Rusak Berat</small>
                          <h5><strong class="text-dark">{{ detail_ruas_jalan.aspal_rusak_berat }}</strong><small></small>
                          </h5>
                        </b-col>
                      </b-row>

                      <b-row>
                        <b-col md="3" xs="3" sm="3" lg="3">
                          <small>Beton Baik</small>
                          <h5><strong class="text-dark">{{ detail_ruas_jalan.beton_baik }} </strong><small></small></h5>
                        </b-col>
                        <b-col md="3" xs="3" sm="3" lg="3">
                          <small>Beton Sedang</small>
                          <h5><strong class="text-dark">{{ detail_ruas_jalan.beton_sedang }} </strong><small></small></h5>
                        </b-col>
                        <b-col md="3" xs="3" sm="3" lg="3">
                          <small>Beton Rusak Ringan</small>
                          <h5><strong class="text-dark">{{ detail_ruas_jalan.beton_rusak_ringan }}
                            </strong><small></small>
                          </h5>
                        </b-col>
                        <b-col md="3" xs="3" sm="3" lg="3">
                          <small>Beton Rusak Berat</small>
                          <h5><strong class="text-dark">{{ detail_ruas_jalan.beton_rusak_berat }}</strong><small></small>
                          </h5>
                        </b-col>
                      </b-row>

                      <b-row>
                        <b-col md="3" xs="3" sm="3" lg="3">
                          <small>Perawatan Rutin</small>
                          <h5 class="text-warning"><small><sup>Rp. </sup></small><strong class="text-dark">{{
                            Rp(detail_ruas_jalan.pr)
                          }}</strong><small></small></h5>
                        </b-col>
                        <b-col md="3" xs="3" sm="3" lg="3">
                          <small>Rutin Kondisi </small>
                          <h5 class="text-warning"> <small><sup>Rp. </sup></small><strong class="text-dark">{{
                            Rp(detail_ruas_jalan.rk) }}
                            </strong><small></small>
                          </h5>
                        </b-col>
                        <b-col md="6" xs="6" sm="6" lg="6">
                          <small>Holding </small>
                          <h5 class="text-warning"><small><sup>Rp. </sup></small><strong class="text-dark">{{
                            Rp(detail_ruas_jalan.holding) }} </strong>
                          </h5>
                        </b-col>
                      </b-row>

                    </b-col>
                  </b-row>
                </div>
              </b-alert>

              <b-alert variant="info" show>
                <div class="alert-body">
                  <b-row>
                    <b-col>
                      <b-row class="text-primary">
                        <b-col col="12"><strong>Pengajuan Peningkatan dan Rehab 2024</strong></b-col>
                      </b-row>
                      <b-row>
                        <b-col col="12"></b-col>
                      </b-row>
                      <b-row>
                        <b-col md="3" xs="3" sm="3" lg="3">
                          <small>Aspal Baik</small>
                          <h5><strong class="text-dark">{{ detail_ruas_jalan.usulan_aspal_baik }} </strong><small></small>
                          </h5>
                        </b-col>
                        <b-col md="3" xs="3" sm="3" lg="3">
                          <small>Aspal Sedang</small>
                          <h5><strong class="text-dark">{{ detail_ruas_jalan.usulan_aspal_sedang }}
                            </strong><small></small>
                          </h5>
                        </b-col>
                        <b-col md="3" xs="3" sm="3" lg="3">
                          <small>Aspal Rusak Ringan</small>
                          <h5><strong class="text-dark">{{ detail_ruas_jalan.usulan_aspal_rusak_ringan }}
                            </strong><small></small>
                          </h5>
                        </b-col>
                        <b-col md="3" xs="3" sm="3" lg="3">
                          <small>Aspal Rusak Berat</small>
                          <h5><strong class="text-dark">{{ detail_ruas_jalan.usulan_aspal_rusak_berat
                          }}</strong><small></small></h5>
                        </b-col>
                      </b-row>

                      <b-row>
                        <b-col md="3" xs="3" sm="3" lg="3">
                          <small>Baik</small>
                          <h5><strong class="text-dark">{{ detail_ruas_jalan.usulan_beton_baik }} </strong><small></small>
                          </h5>
                        </b-col>
                        <b-col md="3" xs="3" sm="3" lg="3">
                          <small>Sedang</small>
                          <h5><strong class="text-dark">{{ detail_ruas_jalan.usulan_beton_sedang }}
                            </strong><small></small>
                          </h5>
                        </b-col>
                        <b-col md="3" xs="3" sm="3" lg="3">
                          <small>R-Ringan</small>
                          <h5><strong class="text-dark">{{ detail_ruas_jalan.usulan_beton_rusak_ringan }}
                            </strong><small></small>
                          </h5>
                        </b-col>
                        <b-col md="3" xs="3" sm="3" lg="3">
                          <small>R-Berat</small>
                          <h5><strong class="text-dark">{{ detail_ruas_jalan.usulan_beton_rusak_berat
                          }}</strong><small></small></h5>
                        </b-col>
                      </b-row>
                    </b-col>

                  </b-row>
                </div>
              </b-alert>

              <b-alert variant="primary" show>
                <div class="alert-body">
                  <b-row>
                    <b-col>
                      <b-row class="text-primary">
                        <b-col col="12"><strong>Kegiatan Rutin </strong></b-col>
                      </b-row>
                      <b-row>
                        <b-col col="12"></b-col>
                      </b-row>
                      <b-row>
                        <b-col md="3" xs="3" sm="3" lg="3">
                          <small>Aspal Baik</small>
                          <h5><strong class="text-dark">{{ detail_ruas_jalan.ruc_aspal_baik }} </strong><small></small>
                          </h5>
                        </b-col>
                        <b-col md="3" xs="3" sm="3" lg="3">
                          <small>Aspal Sedang</small>
                          <h5><strong class="text-dark">{{ detail_ruas_jalan.ruc_aspal_sedang }} </strong><small></small>
                          </h5>
                        </b-col>
                        <b-col md="3" xs="3" sm="3" lg="3">
                          <small>Aspal Rusak Ringan</small>
                          <h5><strong class="text-dark">{{ detail_ruas_jalan.ruc_aspal_rusak_ringan }}
                            </strong><small></small>
                          </h5>
                        </b-col>
                        <b-col md="3" xs="3" sm="3" lg="3">
                          <small>Aspal Rusak Berat</small>
                          <h5><strong class="text-dark">{{ detail_ruas_jalan.ruc_aspal_rusak_berat
                          }}</strong><small></small></h5>
                        </b-col>
                      </b-row>

                      <b-row>
                        <b-col md="3" xs="3" sm="3" lg="3">
                          <small>Beton Baik</small>
                          <h5><strong class="text-dark">{{ detail_ruas_jalan.ruc_beton_baik }} </strong><small></small>
                          </h5>
                        </b-col>
                        <b-col md="3" xs="3" sm="3" lg="3">
                          <small>Beton Sedang</small>
                          <h5><strong class="text-dark">{{ detail_ruas_jalan.ruc_beton_sedang }} </strong><small></small>
                          </h5>
                        </b-col>
                        <b-col md="3" xs="3" sm="3" lg="3">
                          <small>Beton Rusak Ringan</small>
                          <h5><strong class="text-dark">{{ detail_ruas_jalan.ruc_beton_rusak_ringan }}
                            </strong><small></small>
                          </h5>
                        </b-col>
                        <b-col md="3" xs="3" sm="3" lg="3">
                          <small>Beton Rusak Berat</small>
                          <h5><strong class="text-dark">{{ detail_ruas_jalan.ruc_beton_rusak_berat
                          }}</strong><small></small></h5>
                        </b-col>
                      </b-row>

                      <b-row>
                        <b-col md="3" xs="3" sm="3" lg="3">
                          <small>Perawatan Rutin</small>
                          <h5 class="text-warning"><small><sup>Rp. </sup></small><strong class="text-dark">{{
                            Rp(detail_ruas_jalan.ruc_pr)
                          }}</strong><small></small></h5>
                        </b-col>
                        <b-col md="3" xs="3" sm="3" lg="3">
                          <small>Rusak Kondisi </small>
                          <h5 class="text-warning"> <small><sup>Rp. </sup></small><strong class="text-dark">{{
                            Rp(detail_ruas_jalan.ruc_rk) }}
                            </strong><small></small>
                          </h5>
                        </b-col>
                        <b-col md="6" xs="6" sm="6" lg="6">
                          <small>Holding </small>
                          <h5 class="text-warning"><small><sup>Rp. </sup></small><strong class="text-dark">{{
                            Rp(detail_ruas_jalan.ruc_holding) }} </strong>
                          </h5>
                        </b-col>
                      </b-row>
                    </b-col>

                  </b-row>
                </div>
              </b-alert>

            </b-col>
          </b-row>

          <hr>
          <h5 class="text-primary"> <b-avatar rounded="" size="25" variant="primary"><feather-icon icon="GridIcon"
                size='15' /></b-avatar> Detail Usulan Perbakan dan
            Rehabilitasi Jalan</h5>
          <b-alert variant="warning" show v-for="rs in detail_ruc_jalan" v-bind:key="rs">
            <div class="alert-body">
              <b-row>
                <b-col md="5">
                  <h5><small>Balai Bidang </small><br>
                    <small><strong>{{ rs.nama }}</strong></small>
                  </h5>
                  <h5><small>Sub Kegiatab </small><br>
                    <small><strong>{{ rs.kode_sub_kegiatan }}</strong> {{ rs.nama_sub_kegiatan }}</small>
                  </h5>
                  <h5 class="text-primary"><small>Pagu Anggaran </small><br>
                    <small><sup>Rp.</sup><strong>{{ Rp(rs.pagu_usulan) }}</strong> </small>
                  </h5>

                </b-col>
                <b-col md="7">
                  <h5 style="text-align: justify;">
                    <small> <strong>Pekerjaan {{ rs.nama_pekerjaan }}</strong></small><br>
                    <small> <strong>Jenis Peekerjaan {{ rs.jenis_pekerjaan }}</strong></small><br>
                    <small> Detail Lokasi {{ rs.detail_lokasi }}</small><br>
                    <small> <strong>Target {{ rs.target_pekerjaan }}</strong></small><br>
                  </h5>
                </b-col>
              </b-row>

              <b-alert variant="primary" show>
                <div class="alert-body">
                  <b-row>
                    <b-col>
                      <h6 class="text-primary">
                        Detail Usulan Panjang Ruas {{ rs.jenis_pekerjaan }}
                      </h6>
                    </b-col>
                  </b-row>
                  <div class="text-center text-primary">

                    <b-row class="text-center text-primary">
                      <b-col md="3" xs="3" sm="3" lg="3">
                        <small>Aspal Baik</small>
                        <h5><strong class="text-dark">{{ rs.profil_jalan.aspal_baik }} </strong><small></small></h5>
                      </b-col>
                      <b-col md="3" xs="3" sm="3" lg="3">
                        <small>Aspal Sedang</small>
                        <h5><strong class="text-dark">{{ rs.profil_jalan.aspal_sedang }} </strong><small></small></h5>
                      </b-col>
                      <b-col md="3" xs="3" sm="3" lg="3">
                        <small>Aspal Rusak Ringan</small>
                        <h5><strong class="text-dark">{{ rs.profil_jalan.aspal_rusak_ringan }} </strong><small></small>
                        </h5>
                      </b-col>
                      <b-col md="3" xs="3" sm="3" lg="3">
                        <small>Aspal Rusak Berat</small>
                        <h5><strong class="text-dark">{{ rs.profil_jalan.aspal_rusak_berat }}</strong><small></small></h5>
                      </b-col>
                    </b-row>

                    <b-row>
                      <b-col md="3" xs="3" sm="3" lg="3">
                        <small>Beton Baik</small>
                        <h5><strong class="text-dark">{{ rs.profil_jalan.beton_baik }} </strong><small></small></h5>
                      </b-col>
                      <b-col md="3" xs="3" sm="3" lg="3">
                        <small>Beton Sedang</small>
                        <h5><strong class="text-dark">{{ rs.profil_jalan.beton_sedang }} </strong><small></small></h5>
                      </b-col>
                      <b-col md="3" xs="3" sm="3" lg="3">
                        <small>Beton Rusak Ringan</small>
                        <h5><strong class="text-dark">{{ rs.profil_jalan.beton_rusak_ringan }} </strong><small></small>
                        </h5>
                      </b-col>
                      <b-col md="3" xs="3" sm="3" lg="3">
                        <small>Beton Rusak Berat</small>
                        <h5><strong class="text-dark">{{ rs.profil_jalan.beton_rusak_berat }}</strong><small></small></h5>
                      </b-col>
                    </b-row>
                  </div>
                </div>
              </b-alert>

            </div>
          </b-alert>

        </b-col>
      </b-row>
    </b-modal>

  </div>
</template>

<script>
import Base from '@/config/Mixins_base'
import { BModal, BButton, VBModal, BAlert, BRow, BCol, BAvatar } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BButton,
    BModal, BAvatar,
    BAlert, BRow, BCol,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {

    }
  },
  mixins: [Base],
  mounted() {


  },
  emits: [""],
  methods: {
    Rp(value) {
      let val = (value / 1).toFixed(0).replace('.', ',')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    },
    close() {
      this.$root.$emit('bv::hide::modal', 'modal-1')
    }
  },
  props: {
    detail_ruas_jalan: {},
    detail_ruc_jalan: {}
  },
  computed: {
    // setTahun: {
    //   get: function () {
    //	 return this.tahun;
    //   },
    //   set: function (newValue) {
    //	 return this.tahun = newValue;
    //   }
    // }, 

  },
}
</script>