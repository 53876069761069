<template>
  <div>
    <b-row style="margin-bottom: -15px;">
      <b-col>
        <b-card>
          <h6 class="text-primary" style="margin-top: -5px;"><b-avatar rounded="" variant="primary"
              size="20"><feather-icon icon="GridIcon" size='15' /></b-avatar>
            Master RUC Tahun 2024</h6>
          <b-row style="margin-bottom: -10px; margin-top: -5px;">
            <b-col md="2" v-for="rs in master_ruc" v-bind:key="rs">
              <h5>
                <small> {{ rs.keterangan }}</small><br> <sup>Rp. </sup>{{ Rp(rs.nominal_ruc) }} <small><sub>/{{ rs.satuan
                }}</sub></small>
              </h5>
            </b-col>
          </b-row>
          <hr>
          <b-alert variant="primary" show>
            <div class="alert-body">
              <b-row style="margin-bottom: -10px;">
                <b-col md="4">
                  <b-form-group>
                    <label style="margin-top: -5px;">Balai / Bidang</label>
                    <v-select v-model="setidbidaang" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="nama"
                      size="sm" @input="LoadRuasJalan(setidbidaang)" :options="balai_bidang" />
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <h6 class="text-primary"><b-avatar rounded="" variant="primary" size="20"><feather-icon icon="GridIcon"
                        size='15' /></b-avatar> Total Biaya Pemeliharan Rutin Jalan </h6>
                  <strong class="ml-2 text-primary" style="font-size: 20px;"><sup>Rp.</sup>{{
                    Rp(rp_ruas_jalan.perawatan_rutin) }},-</strong>

                </b-col>
                <b-col md="4">
                  <h6 class="text-primary"><b-avatar rounded="" variant="primary" size="20"><feather-icon icon="GridIcon"
                        size='15' /></b-avatar> Biaya Pemeliharan Rutin Jembatan</h6>
                  <strong class="ml-2 text-primary" style="font-size: 20px;"><sup>Rp.</sup>0,-</strong>

                </b-col>
              </b-row>
            </div>
          </b-alert>
        </b-card>
      </b-col>
    </b-row>
    <b-card>
      <tabel-data :ruas_jalan=ruas_jalan :balai_bidang=balai_bidang @LoadRuasJalan=LoadRuasJalan
        @DetailUSulan=DetailUSulan></tabel-data>
    </b-card>
    <detail_usulan :detail_ruas_jalan=detail_ruas_jalan :detail_ruc_jalan=detail_ruc_jalan></detail_usulan>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import axios from '@/config/Axios'
import Base from '@/config/Mixins_base'
import {
  BTabs,
  BTab, BCard, BRow, BAlert,
  BCol, BAvatar
} from 'bootstrap-vue'
import TabelData from './component/tabelData.vue'
import detail_usulan from './component/detail_usulan.vue'
export default {
  components: {
    BTabs, BAvatar,
    BTab, BCard, BRow,
    BCol, detail_usulan,
    TabelData, vSelect
  },
  data() {
    return {
      balai_bidang: [],
      ruas_jalan: [],
      id_bidang: '6',
      setidbidaang: { "id_bidang": "6", "nama": "BPJ Semarang", "keterangan": "Balai Pengelolaan Jalan Wilayah Semarang" },
      master_ruc: [],
      detail_ruas_jalan: {},
      detail_ruc_jalan: [], rp_ruas_jalan: ''
    }
  },
  mixins: [Base],
  mounted() {
    this.get_balai_bidang();
    this.get_ruas_jalan();
    this.nominal_ruas_jalan();
    this.get_ruc();
  },
  methods: {
    DetailUSulan(val) {
      this.detail_ruas_jalan = val;
      this.get_detail_ruc_jalan(val.id_ruas_jalan)
    },

    async get_detail_ruc_jalan(id_ruas_jalan) {
      const self = this;
      await axios({
        method: 'PUT',
        url: '/api/pu/p_ruc/detail_ruc_jalan',
        data: {
          id_ruas_jalan: id_ruas_jalan
        },
        headers: {
          'Authorization': self.isAuthenticated
        }
      })
        .then(function (response) {
          self.detail_ruc_jalan = response.data.result;

        }).catch(err => {
          self.pesan = err.message;
          self.notification('warning', "Error", err.message);
        });
    },
    LoadRuasJalan(val) {
      this.id_bidang = val.id_bidang;
      this.get_ruas_jalan();
      this.nominal_ruas_jalan()
    },

    async get_ruc() {
      const self = this;
      await axios({
        method: 'PUT',
        url: '/api/pu/p_ruc/get_ruc',
        data: {

        },
        headers: {
          'Authorization': self.isAuthenticated
        }
      })
        .then(function (response) {
          self.master_ruc = response.data.result;

        }).catch(err => {
          self.pesan = err.message;
          self.notification('warning', "Error", err.message);
        });
    },
    async get_balai_bidang() {
      const self = this;
      await axios({
        method: 'PUT',
        url: '/api/pu/p_ruc/get_balai_bidang',
        data: {

        },
        headers: {
          'Authorization': self.isAuthenticated
        }
      })
        .then(function (response) {
          self.balai_bidang = response.data.result;
          //self.rs_level = response.data.result;

        }).catch(err => {
          self.pesan = err.message;
          self.notification('warning', "Error", err.message);
        });
    },
    Rp(value) {
      let val = (value / 1).toFixed(0).replace('.', ',')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    },
    async get_ruas_jalan() {
      const self = this;
      await axios({
        method: 'PUT',
        url: '/api/pu/p_ruc/ruas_jalan',
        data: {
          id_bidang: self.id_bidang
        },
        headers: {
          'Authorization': self.isAuthenticated
        }
      })
        .then(function (response) {
          self.ruas_jalan = response.data.result;
          //self.rs_level = response.data.result;

        }).catch(err => {
          self.pesan = err.message;
          self.notification('warning', "Error", err.message);
        });
    },

    async nominal_ruas_jalan() {
      const self = this;
      await axios({
        method: 'PUT',
        url: '/api/pu/p_ruc/nominal_ruas_jalan',
        data: {
          id_bidang: self.id_bidang
        },
        headers: {
          'Authorization': self.isAuthenticated
        }
      })
        .then(function (response) {
          self.rp_ruas_jalan = response.data.result;
          //self.rs_level = response.data.result;

        }).catch(err => {
          self.pesan = err.message;
          self.notification('warning', "Error", err.message);
        });
    },

  },
  props: {

  },
  computed: {
    // setTahun: {
    //   get: function () {
    //	 return this.tahun;
    //   },
    //   set: function (newValue) {
    //	 return this.tahun = newValue;
    //   }
    // }, 

  },

}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>